import Layout from "components/layout";
import React from "react";
import SanDiego from "components/locationIndexPages/SanDiego/index";
import SEO from "components/seo";

function index() {
  const title = "San Diego Translation Services - Translation Agency";
  const description =
    "Need translation services in San Diego? Tomedes provides translation, localization, and interpretation solutions in 120+ languages with rapid delivery and 24/7 support.";
  const keywords = "San Diego Translation Services";
  return (
    <Layout>
      <SEO
        title={title}
        description={description}
        keywords={keywords}
        slug="/locations/san-diego-translation-services"
      />
      <SanDiego />
    </Layout>
  );
}

export default index;
